import { DefaultSeoProps } from 'next-seo'

export const SEO: DefaultSeoProps = {
  titleTemplate: '%s | KnightSwap',
  defaultTitle: 'KnightSwap',
  description:
    'Cheaper and faster than Uniswap? Discover KnightSwap, the leading DEX on EVM chains with the best farms in DeFi.',
  twitter: {
    cardType: 'summary_large_image',
    handle: '@KnightSwap',
    site: '@KnightSwap',
  },
  openGraph: {
    title: 'KnightSwap - A next evolution DeFi exchange on EVM chains',
    description: 'The most popular AMM on BSC/ETH by user count!',
    images: [{ url: 'https://knightswap.net/logo.png' }],
  },
}
